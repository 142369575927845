<template>
   <div>
      <API_keys />
   </div>
</template>

<script>
import API_keys from "../components/API_KEYS/API_keys.vue";

export default {
   components: {
      API_keys,
   },
};
</script>