<template>
    <div class="mb-16">
        <Notification type="error" message="Error" ref="error_noti" />
        <Notification type="success" message="Información actualizada" ref="ok_noti" />
        <v-card class="ma-4">
            <v-card-title class="d-flex justify-center">
                <div class="text-h3">
                    API Keys
                </div>
            </v-card-title>
            <v-card-text>
                <v-text-field label="API key para Sidev OPENPAY" color="secondary"
                    v-model="SIDEV_OPENPAY"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="create_api_key()" block large>
                    <span class="text-primary">
                        <v-icon left>mdi-content-save</v-icon>Guardar
                    </span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            SIDEV_OPENPAY: null,
        };
    },
    methods: {
        create_api_key: async function () {
            if (!this.SIDEV_OPENPAY) {
                this.$refs.error_noti.Show("El campo API key para Sidev OPENPAY no puede estar vacío");
                return;
            }
            navigator.clipboard.writeText(this.SIDEV_OPENPAY)
            try {
                const res = await this.$store.dispatch("updateKeys", {
                    SIDEV_OPENPAY: this.SIDEV_OPENPAY
                });
                if (res.status && res.status == 200)
                    this.$refs.ok_noti.Show();
                if (res.status && res.status == 400)
                    this.$refs.error_noti.Show(res.data.errors);
            } catch (error) {
                console.log(error)
            }
        },
    }
};
</script>

<style></style>